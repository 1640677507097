<template>
  <div>
    <a-button type="primary" @click="showModal('new')">
          新增
    </a-button>
    <a-table
      :columns="columns"
      :dataSource="data"
      :pagination="false"
      :loading="loading"
    >
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="handleById(record, 'edit')">编辑</a>
      </span>
    </a-table>
    <Pagination :current="current" :total="total" @handleChange="handleChange" v-show="total > 20" />
    <a-modal
      :title="title"
      v-model="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确认"
      cancelText="取消"
      width="650px"
    >
      <a-button @click="add" type="default">
        添加
      </a-button>

      <a-form style="padding-bottom: 0px" layout="inline" :form="form2">
        <a-row>
          <a-col :span="6">软件大小</a-col>
          <a-col :span="6">积分</a-col>
          <a-col :span="7">收费单位（M)</a-col>
        </a-row>
        <a-form-item v-for="(item, index) in charges" :key="index">
            <a-input-number  style="margin-right: 10px;width: 25%" v-model="item.scope" @change="onChangemax(index)" @blur="checkNumber(index)"
              placeholder="软件大小" :min="1" :max="10000000">
            </a-input-number>
            <a-input-number style="margin-right: 10px;width: 25%"  v-model="item.points" 
                placeholder="积分" :min="1" :max="10000000">
            </a-input-number>
            <a-input-number  style="margin-right: 10px;width: 25%" v-model="item.traffic_size"
              placeholder="收费单位（M)" :min="1" :max="10000000">
            </a-input-number>
             <a-button @click="deleteBlank(index)" type="default">
              删除
            </a-button>
        </a-form-item>
        <a-form-item v-show="isShow">
          <a-input-number disabled="true" style="margin-right: 10px;width: 25%" v-model="scope"
                placeholder="最大软件大小" :min="1" :max="10000000">
          </a-input-number>
          <a-input-number style="margin-right: 10px;width: 25%"  v-model="points" 
              placeholder="最大积分" :min="1" :max="10000000">
          </a-input-number>
          <a-input-number  style="margin-right: 75px;width: 25%" v-model="traffic_size"
            placeholder="最大收费单位（M)" :min="1" :max="10000000">
          </a-input-number>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { ChargeModel } from "../../../models/charge";
import Pagination from '../../../components/Pagination'
import formatTime from '../../../utils/formatTime'
const chargeModel = new ChargeModel();
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

const columns = [
  {
    title: "序号",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "收费标准",
    dataIndex: "charge_standard",
    key: "charge_standard",
  },
  {
    title: "创建时间",
    dataIndex: "created_at",
    key: "created_at",
    width: 80,
  },
  {
    title: "编辑时间",
    dataIndex: "updated_at",
    key: "updated_at",
  },
  {
    title: '操作',
    dataIndex: '',
    key: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }
];
export default {
  props: {
      scope: {
        type: Number
      },
      points: {
        type: Number
      },
      traffic_size: {
        type: Number
      },
    },
  data() {
    return {
      form1: this.$form.createForm(this, {
        name: "horizontal_login",
      }),
      data: [],
      columns,
      visible: false,
      loading: false,
      total: 0,
      id: 0,
      title: "",
      current: 1,
      charges: [],
      form2: this.$form.createForm(this, { name: "coordinated" }),
      isSubmit: true,
      isShow: false,
    };
  },

  created() {
    this.queryChargesList();
  },

  methods: {
    handleDelete(record) {
      const confirmText = ["确定删除吗？", "删除后将不可恢复"];
      const newDatas = [];
      const h = this.$createElement;
      for (const i in confirmText) {
        newDatas.push(h("p", null, confirmText[i]));
      }
      this.$confirm({
        content: h("div", null, newDatas),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          chargeModel.destroy(record.id).then((res) => {
            if (res.code == 0) {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2,
              });
              this.queryChargesList();
            }
          });
        },
        onCancel() {},
      });
    },
    add() {
      this.isShow = true
      if (this.charges.length == 10){
          this.$notification.open({
                message: "提示",
                description: "最多可添加10条记录",
                duration: 2,
              });
        return;
      } else {
          this.charges.push({
          scope: "",
          points:"",
          traffic_size:""
        });
      } 
    },
    checkNumber(index) {
      if (index != 0 && this.charges[index].scope <= this.charges[index-1].scope ) {
        this.$notification.open({
                message: "提示",
                description: "输入的软件大小必须比上一行大",
                duration: 2,
              });
        this.charges[index].scope = null
        return;
      } 
    },
    onChangemax(index) {
      console.log(index);
      var arr = new Array();
      for (var i = 0; i < this.charges.length; i++) {
        var item = this.charges[i];
        arr.push(item.scope);
      }
      this.scope = Math.max.apply(null,arr);
    },
    deleteBlank(index) {
      this.charges.splice(index, 1)
      var arr = new Array();
      for (var i = 0; i < this.charges.length; i++) {
        var item = this.charges[i];
        arr.push(item.scope);
      }
      this.scope = Math.max.apply(null,arr);
      if (this.charges.length == 0) {
        this.isShow = false
      }
    },
    handleOk(e) {
      e.preventDefault();
      if(this.charges.length == 0){
        this.$notification.open({
                message: "提示",
                description: "请先输入每行小于的值",
                duration: 2,
              });
        e.preventDefault();
        return;
      }
      var error_message = ""
      if (this.scope === null || !this.scope || typeof(this.scope)=="undefined") {
        error_message = "最大软件大小,"
      }
       if (this.points === null || !this.points ||typeof(this.points)=="undefined") {
        error_message += "最大积分,"
      }
       if (this.traffic_size === null || !this.traffic_size ||typeof(this.traffic_size)=="undefined") {
        error_message += "最大收费单位,"
      }
      for (var i = 0; i < this.charges.length; i++) {
        var item = this.charges[i];
        var j = i+1
        if (item.scope === null || !item.scope || typeof(item.scope)=="undefined") {
            error_message += "第" + j.toString() + "行软件大小,"
        }
        if (item.points === null || !item.points || typeof(item.points)=="undefined") {
            error_message += "第" + j.toString() + "行积分,"
        }
        if (item.traffic_size === null || !item.traffic_size || typeof(item.traffic_size)=="undefined") {
            error_message += "第" + j.toString() + "行收费单位,"
        }
      }
      if (error_message != ""){
        this.$notification.open({
                message: "提示",
                description: "请先输入"+error_message,
                duration: 2,
              });
        e.preventDefault();
        return;
      }
      if (this.isSubmit) {
        let data = {}
        data.charges = this.charges
        data.scope = this.scope
        data.points = this.points
        data.traffic_size = this.traffic_size
        data.id = this.id
        this.$notification.open({
                message: "提示",
                description: "数据处理中请稍后！",
                duration: 2,
              });
        this.isSubmit = false;
        chargeModel.create(data).then((res) => {
          if (res.code == 0) {
            this.$notification.open({
              message: "提示",
              description: res.message,
              duration: 2,
            });
            this.visible = false;
            this.charges = []
            this.scope = ""
            this.points = ""
            this.traffic_size = ""
            this.id = 0
            this.queryChargesList();
            this.isSubmit = true;
          }
          else{
            this.$notification.open({
              message: "提示",
              description: res.message,
              duration: 2,
            });
          }
          });
        }
    },

    handleCancel() {
      this.form2.resetFields();
      this.charges = []
      this.scope = ""
      this.points = ""
      this.traffic_size = ""
      this.isShow = false
    },
    handleById(record) {
      this.title = "编辑";
      this.id = record.id;
      this.isShow = true;
      chargeModel.update(this.id).then((res) => {
          if (res.code == 0) {
            this.charges = res.result
            this.scope = res.charge_datail.scope
            this.points = res.charge_datail.points
            this.traffic_size = res.charge_datail.traffic_size
            console.log(res.result);
            this.visible = true;
          }
        });
    },

    showModal() {
      this.visible = true;
      this.title = "新增";
    },
    handleChange(page) {
      this.current = page
      console.log(page);
      this.queryChargesList(page)
    },
    queryChargesList(page) {
      this.loading = true;
      chargeModel.index(page).then((res) => {
        if (res.code == 0) {
          this.current = page;
          res.result.map((item, index) => {
            console.log(index);
            item.created_at = formatTime(item.created_at)
            item.updated_at = formatTime(item.updated_at)
            return item
          })
          this.data = res.result;
          this.loading = false;
          this.total = res.total;
        }
      });
    },
  },

  components: {
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
.sm-select {
  width: 170px;
}
</style>