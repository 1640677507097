import {
    HTTP
} from '../utils/axios'

class ChargeModel extends HTTP {
    /*首页渲染*/
    index(page = 1) {
        let data = {}
        data.page = page
        return this.request({
            url: 'board/charges',
            method: "GET",
            data: data
        })
    }

    /*创建*/
    create(value) {
        return this.request({
            url: "board/charges/",
            method: "POST",
            data: value,
        })
    }

    /*修改*/
    update(id) {
        return this.request({
            url: "board/charges/" + id,
            method: "PUT"
        })
    }

    /*删除*/
    destroy(id) {
        return this.request({
            url: "board/charges/" + id,
            method: "DELETE"
        })
    }
}

export {
    ChargeModel
}